<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Testimonial List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:300ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :loading="loading"
        :key="items.name"
        :options.sync="pagination"
        :footer-props="{'items-per-page-options':[30, 45, 60, -1]}"
        class="elevation-1 page__table"
        :show-select="false"
        :disable-pagination="true"
        :hide-default-footer="true"
      >
         <template v-slot:body="">
          <draggable
            :list="items.results"
            v-bind="dragOptions"
            tag="tbody"
            @change="changeOrder"
          >
            <tr
              v-for="(testimonial, index) in items.results"
              :key="index"
              style="cursor: move !important;"
            >
              <td>
                <v-icon style="cursor: pointer;" title="Drag">
                  mdi-drag 
                </v-icon>
              </td>
              <td>
                {{ testimonial.customer_name }}
              </td>
              <td>
                {{ testimonial.designation ? testimonial.designation : "N/A" }}
              </td>
              <td>
                <v-btn
                  color="grey darken-2"
                  icon
                  dark
                  :to="'/testimonial/edit/' + testimonial.id"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="grey darken-2" icon dark @click="confirmDelete(testimonial)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5"
          >Are you sure delete <b>{{ testimonial.customer_name }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(testimonial)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" fixed fab bottom right to="/testimonial/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { parseParams,getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "Testimonials",
  components: {
    draggable
  },
  mounted() {
    this.getItems();
  },
  data() {
    return {
      loading: false,
      dialog: false,
      testimonial: {
        name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: '', value: '', sortable: false },
        { text: "Customer Name", value: "customer_name", sortable: false  },
        { text: "Designation", value: "designation", sortable: false  },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: -1,
        sortBy: ["order"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets()
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "customer_name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "customer_name",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = true;
      let url = parseParams(this.pageData);
      this.$axios
        .get("admin/testimonial/list?"+url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.testimonial = item;
    },
    deleteItem(item) {
      const index = this.items?.results.indexOf(item);
      let _self = this;
      _self.loading = true;
      this.$axios
        .delete(`/admin/testimonial/delete/${item.id}`)
        .then((res) => {
          if (res?.data.status) {
            _self.dialog = false;
            _self.setAlert({
              show: true,
              variant: "success",
              message: res.data.message,
              dismissCountDown: 5000,
            });
            _self.items.results.splice(index, 1);
          }
          _self.loading = false;
          _self.dialog = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    changeOrder() {
      let _self = this;
      _self.loading = true;

      let new_order = [];

      for(let i=0; i<_self.items.results.length; i++) {
        new_order.push({
          index: i,
          id: _self.items.results[i].id
        });
      }

      this.$axios
        .post("/admin/testimonial/change_order", {
          new_order: new_order
        })
        .then((res) => {
          if (res.status) {
           res 
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    }
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 1;
  background: #dadada80 !important;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff;
}
</style>